import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import NewsElement from '../components/news-element'
import '../page-styles/aboutUs.css'
import Header from '../components/Header';
import SlideNavBar from '../components/slideNavBar';
import Loader from '../loader/loader';
import NewsAreaElement from '../components/newsAreaElement'
import defoultLogo from '../assets/defoultPhoto.png';
import defoultLogo2 from '../assets/defoultPhotoLogo.png';
import card1 from '../assets/cards27.png';
import card2 from '../assets/cards24.png';
import card3 from '../assets/cards25.png';
import card4 from '../assets/cards26.png';



class AboutUs extends React.Component {
    constructor(props){
        super(props)
    }
    render () {
        return (
            <div className = 'news' >
                <SlideNavBar openSlideNav = {this.openSlideNav}/>
                <Header backgroundImage = {defoultLogo2} />
                    <div className = 'wrapper' style = {{height : 'auto',textAlign: 'center'}}> 
                        <div className = 'aboutUsContennt'>
                            <section className  = 'aboutUsContent'>
                                <h2 className = 'aboutUsHeader'>
                                    <Translate content = 'aboutUsHeader' />
                                </h2>
                                <p className = 'features' >
                                <Translate content = 'features1' />
                                </p>
                               <h3 className = 'aboutUsHeader'> <Translate content = 'aboutUsHeader2' /></h3><br/>
                                <p className = 'features'>
                                <Translate content = 'blot4friends' /><br/>
                                <Translate content = 'blot151and301' /><br/>
                                <Translate content = 'blot2friends' /><br/>
                                <Translate content = 'blotfortwo' /><br/>
                                <Translate content = 'addFriendText' /><br/>
                                <Translate content = 'powerHit' /> <br/>
                                <Translate content = 'tariningFooter' /><br/>
                                <br/>
                                <Translate content = 'colorsAndThemes' /> <br/>
                                </p>
                                
                                <p className = 'features'>
                                    <Translate content = "features2" />
                                </p>
                                <p className = 'features'>
                                <Translate content = "features3" />
                                </p>
                                <p className = 'features'>
                                <Translate content = "features4" />
                                </p>                            
                            </section> 
                            <div className = 'ourTeam'>
                                <h2 className = 'aboutUsHeader'> <Translate content = 'ourTeam' /> </h2>
                                <div className = 'teamMembers'>
                                    <div className = 'member' >
                                        <img src={card1} alt=""/>
                                        <h4 className = 'memberName'><Translate content = 'Varik' /></h4>
                                    </div>
                                    <div className = 'member' >
                                        <img src={card3} alt=""/>
                                        <h4 className = 'memberName'><Translate content = 'Lilith' /></h4>
                                    </div>
                                    <div className = 'member' >
                                        <img src={card2} alt=""/>
                                        <h4 className = 'memberName'><Translate content = 'Ruben' /></h4>
                                    </div>
                                    <div className = 'member' >
                                        <img src={card4} alt=""/>
                                        <h4 className = 'memberName'><Translate content = 'Andrey' /></h4>
                                    </div>
                                </div>
                            </div>        
                        </div>   
                    </div>
                <Footer />
            </div>
        )
    }
    openSlideNav() {
        let direction = document.getElementById("mySidenav").style.width === "250px";
        document.getElementById("mySidenav").style.width = direction ? '0px' : '250px';
    }
     
}
export default AboutUs;