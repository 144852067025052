import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import NewsElement from '../components/news-element'
import '../page-styles/news.css'
import Header from '../components/Header';
import SlideNavBar from '../components/slideNavBar';
import defoultLogo from '../assets/defoultPhoto.png';
import defoultLogo2 from '../assets/defoultPhotoLogo.png';
import neon from '../assets/neon.png';
import neon2 from '../assets/neonNews.png';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
  } 
else {
    counterpart.setLocale('am')
}

class News extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            news:[],
        }
    }

    render() {
        return (
            <dir id = 'news'>
                <SlideNavBar openSlideNav = {this.openSlideNav}/>
                <Header backgroundImage = {neon2} />
                <div className = 'newsCont' >
                {this.state.news.map((v,i) => {
                        return <NewsElement link = {`/news/${this.state.news.length - i - 1 }`} key = {i} header = {v.title} description = {v.text} photo = {v.photo || defoultLogo2} text = {v.text} />
                    })}
                </div>
                
                <Footer />
            </dir>
        )
    }
    componentDidMount () {
        fetch('/api/blog/posts')
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            this.setState((state) => {
                return {
                    news : data.reverse()
                }      
            })
        })
    }
    openSlideNav() {
        let direction = document.getElementById("mySidenav").style.width === "250px";
        document.getElementById("mySidenav").style.width = direction ? '0px' : '250px';
    }
     

}
export default News;