import React from 'react';
import { Helmet } from 'react-helmet'
import { getParameterByName } from '../utils';
import bg from "../assets/kargin_blot_bazarblot.png";
import qr from "../assets/qr.png";
import SlideSow from '../components/SlideShow';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import SlideNavBar from '../components/slideNavBar';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import GameTypes from '../components/gameTypes';
import LastNews from '../components/lastNews';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
  counterpart.setLocale(localStorage.getItem('leng'))
} 
else {
  counterpart.setLocale('am')
}

const HEIGHT = 1050;
const WIDTH = 1680;
const ITEM_HEIGHT = 60;
const FACEBOOK_LINK = "https://apps.facebook.com/1143001412547799";
const OK_LINK = "https://ok.ru/game/1276583168";
const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.karginblot.steadfast";
const APPLE_STORE_LINK =
  "https://apps.apple.com/am/app/kargin-blot/id1465602127";

const KARGIN_BLOT_LOGO =
  "Kargin Blot is a new bazar blot game with cool features, beautiful interface and tournaments. In the app you will find different rooms to play in and engage in fun and entertaining game with real players. There are no bots in the app so you only face real users. The game offers various ways to earn free chips such as daily bonuses, lucky wheel and ads. Կարգին Բլոտը նոր հայկական բազար բլոտ խաղ է, որն ունի հետաքրքիր ֆունկցիաներ, գեղեցիկ ինտերֆեյս և տարբեր մրցաշարեր։ Կարող եք ընտրել խաղի 3  սեղաններից ցանկացածը և ներգրավվել թեժ մրցման մեջ իրական խաղացողների հետ, քանի որ խաղում չկան բոտեր։ Կան անվճար չիպեր ստանալու մի շարք հնարավորություններ՝ օրական բոնուսներ, հաջողության անիվ, գովազդների դիտում․ Kargin Blot- новая Bazar Blot игра с классными функциями, красивым интерфейсом и турнирами. В приложении вы найдете разные столы, в которых можно играть и участвовать в веселой и увлекательной игре с реальными игроками, так как в приложении нет ботов. Игра предлагает множество способов заработать бесплатные фишки- ежедневные бонусы, счастливое колесо и реклама.";

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      lang: 'en',
    }
  } 
  componentDidMount() {
    window.addEventListener('resize', () => this.forceUpdate());

    if (window.location.pathname.indexOf('share') !== -1) {
      window.location.href = `karginblot://${getParameterByName('link')}`;
    }
  }

  render() {
    let width = window.innerWidth;
    let imageHeight = width * HEIGHT / WIDTH;
    let itemHeight = width * ITEM_HEIGHT / WIDTH;

    return (
      <div className="App">
        <Helmet>
          <title>Kargin Blot</title>
          <meta name="description" content={KARGIN_BLOT_LOGO} />
          <meta name="description" content={'belote blot kargin blot Armenian Blot Bazar blot'} />
        </Helmet>
        <Navbar openSlideNav = {this.openSlideNav}/>
        <SlideNavBar openSlideNav = {this.openSlideNav}/>
        <SlideSow />
        <GameTypes />
        <LastNews />
        <Footer />
      </div>
    );
  }
  openSlideNav() {
   let direction = document.getElementById("mySidenav").style.width === "250px";
   document.getElementById("mySidenav").style.width = direction ? '0px' : '250px';
  }

  onLangChange = (e) => {
    this.setState({lang: e.target.value});
    counterpart.setLocale(e.target.value);
  }
}

export default App;
