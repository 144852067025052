import React from "react";
import { getParameterByName, isBrowserMobile } from "../utils";
import fb from "../assets/fb.png";
import ok from "../assets/ok.png";

const FACEBOOK_LINK = `https://apps.facebook.com/1143001412547799?deeplink=${getParameterByName(
  "link"
)}`;
const OK_LINK = `https://ok.ru/game/1276583168?deeplink=${getParameterByName(
  "link"
)}`;

class Links extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", () => this.forceUpdate());
    console.log("isMobile", isBrowserMobile());
    const isMobile = isBrowserMobile();
    // mobile
    if (isMobile) {
      window.location.href = `karginblot://${getParameterByName("link")}`;
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "center", flex: 1 }}
          onClick={() => {
            window.open(FACEBOOK_LINK, "_self");
          }}
        >
          <img src={fb} style={{ maxWidth: "60%" }} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            marginLeft: 20,
          }}
          onClick={() => {
            window.open(OK_LINK, "_self");
          }}
        >
          <img src={ok} style={{ maxWidth: "60%" }} />
        </div>
      </div>
    );
  }
}

export default Links;
