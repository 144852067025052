import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Links from "./pages/Links";
import Main from "./pages/Main";
import News from './pages/News';
import NewsArea from './pages/NewsArea';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: true,
      news:[]
    }
  }
  componentDidMount () {
    fetch('/api/blog/posts')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        this.setState((state) => {
            return {
                news : data,
                loading: false
            }      
        })
    })
}
  render() {
    return (
      <React.Fragment>
      <Router>
        <Switch>
        <Route path="/"  exact = {true}>
            <Main />
          </Route>
          <Route  path="/share">
            <Links />
          </Route>
          <Route path ="/news" exact = {true}>
            <News />
          </Route>
          <Route path ="/about" exact = {true}>
            <AboutUs />
          </Route>
          <Route path ="/contact" exact = {true}>
            <ContactUs />
          </Route>
          <Route path = '/news/:id' render={ (props) => (<NewsArea {...props} news={this.state.news} loading={this.state.loading} />) }/>
          <Redirect to="/"/>
        </Switch>
      </Router>
      </React.Fragment>
    );
  }
}

export default App;
