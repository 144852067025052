import React from 'react';
import Footer from '../components/footer';
import NewsElement from '../components/news-element'
import '../page-styles/contactUs.css'
import Header from '../components/Header';
import SlideNavBar from '../components/slideNavBar';
import fb from '../assets/fb.png';
import ok from '../assets/ok.png';
import mail from '../assets/mail.png';
import defoultLogo from '../assets/defoultPhoto.png';
import defoultLogo2 from '../assets/defoultPhotoLogo.png';


class ContactUs extends React.Component {
    constructor(props){
        super(props)
    }
    render () {
        return (
            <div className = 'news' >
                <SlideNavBar openSlideNav = {this.openSlideNav}/>
                <Header backgroundImage = {defoultLogo2} />
                    <div className = 'wrapper' style = {{height : 'auto',textAlign: 'center',}}> 
                        <div className = 'contacts'>
                            <div className = 'contact' >
                                <img src = {fb} alt="" onClick={() => {
                                    window.open('https://www.facebook.com/messages/t/blotkargin', "_blank");
                                }} />
                            </div>
                            <div className = 'contact' >
                                <img src = {ok} alt=""  onClick={() => {
                                    window.open('https://ok.ru/messages/578472232841', "_blank");
                                }} />
                            </div>
                            <div className = 'contact' onClick={() => {
                                    window.open('https://steadfast.tech/', "_blank");
                                }} >
                                <img src = {mail} alt=""/>
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        )
    }
    openSlideNav() {
        let direction = document.getElementById("mySidenav").style.width === "250px";
        document.getElementById("mySidenav").style.width = direction ? '0px' : '250px';
    }
     
}
export default ContactUs;