import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import NewsElement from '../components/news-element'
import '../page-styles/news.css'
import Header from '../components/Header';
import SlideNavBar from '../components/slideNavBar';
import Loader from '../loader/loader';
import NewsAreaElement from '../components/newsAreaElement'

import defoultLogo2 from '../assets/defoultPhotoLogo.png';


class NewsArea extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            content : null,
            showLastNewsElement: false,
            lastElement: null,
        }
    }
    render () {
        return (
            <div id = 'news' >
                <SlideNavBar openSlideNav = {this.openSlideNav}/>
                <Header backgroundImage = {defoultLogo2}/>
                    <div className = 'wrapper' style = {{height : this.state.content ? 'auto' : '70vh'}}> 
                        { 
                            this.state.content 
                            ?  <NewsAreaElement key = {this.state.content.id} video = {this.state.content.video}  header = {this.state.content.title} description = {this.state.content.text} photo = {this.state.content.photo || defoultLogo2}  /> 
                            : this.state.showLastNewsElement && this.state.lastElement ?
                             <React.Fragment>
                                 <h1 className = 'notFound'>ARTICLE NOT FOUND</h1>
                                 <h2 className = 'notFound' >DID YOU MEAN?</h2>  
                                 <NewsElement link = {`/news/${this.props.news.length - 1}`} key = {this.state.lastElement.id} header = {this.state.lastElement.title} description = {this.state.lastElement.text} video = {this.state.lastElement.video}  photo = {this.state.lastElement.photo || defoultLogo2}  />                         
                            </React.Fragment> 
                            : <Loader />
                        }    
                    </div>    
                <Footer />
            </div>
        )
    }
    componentDidMount(){
        if(this.props.news.length && this.props.news[Number(this.props.match.params.id)]) {
            this.setState({
                content : this.props.news[Number(this.props.match.params.id)],
                lastElement :  this.props.news.length ?  this.props.news[this.props.news.length - 1] : null,
            })
        }
    }

    componentWillReceiveProps(props){
        const isExcitingData = props.news[Number(props.match.params.id)]
        if(isExcitingData) {
            this.setState({
                content : props.news[Number(props.match.params.id)],
            })
        } else {
            this.setState({
                showLastNewsElement : true,
                lastElement :  props.news.length ?  props.news[props.news.length - 1] : null,
            })
        }
    }
    openSlideNav() {
        let direction = document.getElementById("mySidenav").style.width === "250px";
        document.getElementById("mySidenav").style.width = direction ? '0px' : '250px';
    }
}

export default NewsArea;