import React from 'react';
import Translate, { translate } from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import neon from '../assets/neon.png';
import { Link } from 'react-router-dom';

import LocaleSwitcher from './LocaleSwitcher'
import counterpart from 'counterpart';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
  } 
  else {
    counterpart.setLocale('am')
  }

class LastNews extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            news:[],
            position:null,
            slideIndex:0,
            lastChanged:null
        }
    }
    render(){
        return (
            <div className="slider-conteiner" >
                <h2 className = 'gameTypeHeader' ><Translate content = 'lastNews' /></h2>
                <div className = "slider-viewport" style = {{backgroundImage:`url(${neon})` }}>
                <div className = 'dots' style = {{ zIndex:2}}>
                    <a className ="prev" onClick = {() => this.plusSlides(false,-1)} >&#10094;</a>
                    <a className ="next" onClick = {() => this.plusSlides(true,1)}>&#10095;</a>
                </div>  
                    <ul className = "slides" id = 'slides'  >
                    {this.state.news.map((v,i) => {
                        return <News link = {`/news/${i}`} key = {i} header = {v.title} description = {v.text} img = {v.photo} text = {v.text} />
                    })}
                    </ul>
                 </div>
            </div>
        )       
    }
    componentDidMount () {
        fetch('/api/blog/posts')
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            this.setState((state) => {
                return {
                    news : data
                }      
            })
        })
        .then(() => {
            this.plusSlides(true,1)
        })  
        const slidesCont = document.getElementById('slides');
        let items = document.getElementsByClassName('item');
        window.addEventListener('resize',() => {        
            for(let item of items){
                item.style.transform = null;
            }
            slidesCont.style.transform = `translateX(${0}px)`;
            this.setState({
                position:null,
                lastChanged:null,
                slideIndex:0,
            })
        }) 
    }

    plusSlides(status,n) { 
        let current = this.state.slideIndex + n
        this.setState((state) => {
          return {
            slideIndex : state.slideIndex + n
          } 
        })
        this.nextSlide(status,current)
      }
      

    nextSlide(status,n){
        let items = document.getElementsByClassName('item')
        if(items.length <= 3) {
            return
        }
        if (n > items.length) {
            this.setState({
                slideIndex : 1
            })
            n = 1
          }    
          if (n < 1) {
            this.setState( {
                slideIndex : items.length
            })
            n = items.length
          }
        const slidesCont = document.getElementById('slides');
        if(status) {
            items[items.length - n].style.transform = `translateX(${-((((window.innerWidth/100) * 37) * (items.length - n + 1 )) + this.state.position)}px)`           
            this.setState((state) => {
                return {
                    lastChanged: items.length - n
                }
            })
            slidesCont.style.transform = `translateX(${this.state.position + ((window.innerWidth/100) * 37)}px)`;
            this.setState((state) => {
                return {
                    position : state.position + ((window.innerWidth/100) * 37)
                }
            })
        } else {
            let changble = 0
            if(this.state.lastChanged && this.state.lastChanged < items.length) {
                changble = this.state.lastChanged
            }
            items[changble].style.transform = `translateX(${((((window.innerWidth/100) * 37) * (items.length - changble )) - this.state.position)}px)`    
            
            this.setState((state) => {
                return {
                    position : state.position - ((window.innerWidth/100) * 37)
                }
            })
            this.setState((state) => {
                return {
                    lastChanged: ++changble
                }
            })
            slidesCont.style.transform = `translateX(${this.state.position - ((window.innerWidth/100) * 37)}px)`;  
        }
    }
}

class News extends React.Component {
    constructor(props) {
        super(props)
    }
    render () {
        this.header = '';
        if(this.props.header.length > 40){
            this.header = this.props.header.substring(0,35) + '...'
        } else {
            this.header = this.props.header
        }
        this.text = '';
        if(this.props.text.length > 150) {
            this.text = this.props.text.substring(0,95) + '...';
        }
        return (
            <Link to = {this.props.link}>
            <li className = 'item'>
            <span>{
                this.header
            }</span>    
            <img src={this.props.img}/>                                    
                <div className="caption-info">                                        
                    <div className="caption-info-head-left">
                        <p>
                            {
                                this.text.length ? this.text : this.props.text
                            }
                        </p>
                    </div>                                       
                </div>
            </li>
            </Link>
        )
    }
} 

export default LastNews;