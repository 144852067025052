import React from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import LocaleSwitcher from './LocaleSwitcher'
import { Link } from 'react-router-dom';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
  counterpart.setLocale(localStorage.getItem('leng'))
} 
else {
  counterpart.setLocale('am')
}


class SlideNavBar extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
      return (
        <div id="mySidenav" className ="sidenav">
            <a href="#" className ="closebtn" onClick = {this.props.openSlideNav} >&times;</a>
            <Link to = '/' ><Translate content = 'home'/></Link>
            <Link to = '/news' ><Translate content = 'news'/></Link>
            <Link to = '/contact' ><Translate content = 'contact'/></Link>
            <Link to = '/about' > <Translate content = 'about'/></Link>
            <div className = 'switcher'> <LocaleSwitcher color = {'white'} /></div>
        </div>
      );
    }
}
export default SlideNavBar;