import React from "react";
import '../page-styles/loader.css'

class Loader extends React.Component {
    render() {
        return (
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        )
    }
} 

export default Loader;