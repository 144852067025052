import React from 'react';
import fb from '../assets/facebookicon.png';
import ok from '../assets/ok1.png';
import appstore from '../assets/appstore.png';
import google from '../assets/google.png'


const FACEBOOK_LINK = "https://apps.facebook.com/1143001412547799";
const OK_LINK = "https://ok.ru/game/1276583168";
const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.karginblot.steadfast";
const APPLE_STORE_LINK =
  "https://apps.apple.com/am/app/kargin-blot/id1465602127";

class Slide extends React.Component {
    constructor(props) {
        super(props);
      }
    render() {
      return (
        <div className ="mySlides fade" style = {{width: "100%" }}>
          <img src={this.props.img} style = {{width: "100%"}} className = 'slideImg'/>
          <div className = "linksCont" >
              <div className='link fbok' style={{  }} onClick={() => {
                window.open(FACEBOOK_LINK, "_self");
              }} >
                <img src = {fb}/>
              </div>
              <div className='link fbok' style={{  }} onClick={() => {
                window.open(OK_LINK, "_self");
              }} >
                <img src = {ok}/>
              </div>
              <div className='link' style={{  }} onClick={() => {
                window.open(GOOGLE_PLAY_LINK, "_self");
              }} >
                <img src = {google}/>
              </div>
              <div className='link' style={{  }} onClick={() => {
                window.open(APPLE_STORE_LINK, "_self");
              }} >
                <img src = {appstore}/>
              </div>
          </div>
        </div>
      );
    }
}
export default Slide;