import React from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import '../page-styles/news.css'
import ReactPlayer from 'react-player';
import play from '../assets/play.png';
import replay from '../assets/replayWhite.png';


counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
  } 
else {
    counterpart.setLocale('am')
}

class NewsAreaElement extends React.Component {
    constructor(props){
        super(props)
    }
    render() {
        let width = window.innerWidth;
        let height = window.innerHeight;
        return (
            <div className = 'news-area-element gameType ' style = {{height: 'auto' ,minWidth:'220px'}}>
                <div className = 'news-area-content' id = 'content'>
                    <h1 className = 'newsHeader' id = 'hd'>  
                        {this.props.header}
                    </h1>
                        {
                           this.props.video ? <Player video = {this.props.video} /> : <img src = {this.props.photo} alt="" style = {{ width: '100%' }}/>
                        }
                    <div className = 'description'>
                    <p style = {{ fontsSize: '1.3em'}} className = 'newsAreaElementP'>
                            {
                                this.props.description
                            }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default NewsAreaElement;

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            ended : false,
            playAgain : false,
            muted: false,
        }
        this.play = this.play.bind(this)
    }
    render () {
        return (
            <div className = 'playerConteiner'>
                <ReactPlayer  
                    onEnded = {() => {
                        this.setState({ended : true,playing : false});
                    }}
                    valume = {1}
                    muted = {this.state.muted}
                    onClick = {() => this.play(!this.state.playing)} 
                    id = 'video' className = 'videoPlayer' 
                    style = {{margin: 'auto',height: '45vw',maxWidth: '100%'}} 
                    width = {'auto'} height = {'60vh'} 
                    playing = {this.state.playing} 
                    url = {this.props.video} 
                /> 
                <img src = {this.state.ended ? replay : play} style = {{visibility : this.state.playing ? 'hidden' :  'visible'}} className = 'playBtn' onClick = {() => this.play(true)} />   
            </div>
        )
    }

    play (status) {
        this.setState({
            playing: status,
            ended: false,
        })
    }

}