import React from 'react';
import bg from "../assets/kargin_blot_bazarblot.png";
import Slide from './slide'
import bg1 from '../assets/bg1.png'
import bg2 from '../assets/bg2.png'
import bg4 from '../assets/bg3.png'


const bgs = [bg1,bg2,bg4];

class SlideShow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           slideIndex : 1, 
        };
      }
      componentDidMount() {
        this.showSlides(this.state.slideIndex)
      }
    render() {
      return (
        <div className="slideshow-container">
          {
            bgs.map((bg,i) => <Slide img = {bg} key = {i} index = {i} />)
            
           }
        <div className = 'dots'>
          <a className ="prev" onClick = {() => this.plusSlides(-1)}>&#10094;</a>
          <a className ="next" onClick = {() => this.plusSlides(1)}>&#10095;</a>
        </div>        
        {/* <div style={{textAlign: "center"}}>
            {bgs.map((bg,i) => {
              return <Dot index = {i} key = {i} currentSlide = {() => this.currentSlide(i)}/>
            })}
        </div> */}
        
        </div>

      );
    }
      plusSlides(n) {
        let current = this.state.slideIndex + n
        this.setState((state) => {
          return {
            slideIndex : state.slideIndex + n
          } 
        })
        this.showSlides(current);
      }
      
      currentSlide(n) {
        this.setState({
            slideIndex : n
          } 
        )
        this.showSlides(n);
      }
      
      showSlides(n) {
        let i;
        const slides = document.getElementsByClassName("mySlides");
        // const dots = document.getElementsByClassName("dot");
        if (n > slides.length) {
          this.setState({
              slideIndex : 1
          })
          n = 1
        }    
        if (n < 1) {
          this.setState( {
              slideIndex : slides.length
          })
          n = slides.length
        }
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";  
        }
        // for (i = 0; i < dots.length; i++) {
        //     dots[i].className = dots[i].className.replace(" active", "");
        // }
        slides[n -1].style.display = "block"; 
        // dots[this.state.slideIndex -1].className += " active";
      }
  }
  class Dot extends React.Component{
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <span className = "dot"  onClick={this.props.currentSlide}></span>
      )
    }
  }
  
  export default SlideShow;
