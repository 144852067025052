import React from 'react';
import fb from '../assets/facebookicon.png';
import ok from '../assets/ok1.png';
import appstore from '../assets/appstore.png';
import google from '../assets/google.png'
import defoultLogo from '../assets/defoultPhoto.png';
import defoultLogo2 from '../assets/defoultPhotoLogo.png'
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import { Helmet } from 'react-helmet';
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
} 
else {
    counterpart.setLocale('am')
}

const FACEBOOK_LINK = "https://apps.facebook.com/1143001412547799";
const OK_LINK = "https://ok.ru/game/1276583168";
const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.karginblot.steadfast";
const APPLE_STORE_LINK =
  "https://apps.apple.com/am/app/kargin-blot/id1465602127";

class Footer extends React.Component {
    constructor(props) {
        super(props);
      }
      render () {
          return (
            <div className = "footer" >
                <img src = {defoultLogo}  alt="" className = 'footerBg'/>
                <div className = 'categories' >
                    <div className = 'aboutUs content'>
                        <h2><Translate content = 'about' /></h2>
                        <section>
                            <Translate content = 'aboutUs1' /><br/> 
                                <button onClick = {() =>  this.showMore(true)} id = 'showMore' className = 'btn' >Show More</button>
                                <p id = 'showMoreContent'>
                                   <Translate content = 'aboutUs2' /><br/>
                                   <br/>
                                    <Translate content = 'aboutUs3' /> <br/>
                                    <Translate content = 'blot4friends' /><br/>
                                    <Translate content = 'blot2friends' /><br/>
                                    <Translate content = 'blotfortwo' /> <br/>
                                    <Translate content = 'blot151and301' /> <br/>
                                    <Translate content = 'addFriend' /><br/>
                                    <Translate content = 'powerHit' /> <br/>
                                    <Translate content = 'themesFooter' />  <br/>
                                    <Translate content = 'tariningFooter' /> <br/>
                                    <br/>
                                    <Translate content = 'downloadFooter' /> <br/>
                                    <Translate content = 'downloadFooter2' /><br/>
                                    <br/>
                                    <Translate content = 'suggestions' /> <br/>
                                    E-mail: <a onClick={() => {
                                    window.open('https://steadfast.tech/', "_blank");
                                }} >gamefast@steadfast.tech</a> <br/>
                                </p>
                                <button onClick = {() =>  this.showMore(false)} id = 'showLess' className = 'btn'>Show Less</button>
                            </section>
                    </div>
                    <div className = 'contactUs content'>
                    <h2><Translate content = 'contact' /></h2>
                        <div className = 'contactContent'>
                            <i className = "fa fa-envelope-o" aria-hidden="true" style = {{cursor: 'pointer'}} onClick={() => {
                                    window.open('https://steadfast.tech/', "_blank");
                                }}></i>
                            <i className = "fa fa-instagram" aria-hidden="true" style = {{cursor: 'pointer'}} onClick={() => {
                                    window.open('https://www.instagram.com/kargin_blot/?hl=ru', "_blank");
                                }}></i>
                            <i className = "fab fa-facebook-messenger" style = {{cursor: 'pointer'}} onClick={() => {
                                    window.open('https://www.facebook.com/messages/t/blotkargin', "_blank");
                                }}></i>
                        </div>
                        <h2> <Translate content = 'playHere' /> </h2>
                        <div className = 'paragraph1'>
                                <div className='footerLink' style={{ cursor: 'pointer' }} onClick={() => {
                                    window.open(FACEBOOK_LINK, "_self");
                                }} >
                                    <img src = {fb}/>
                                </div>
                                <div className='footerLink' style={{ cursor: 'pointer' }} onClick={() => {
                                    window.open(OK_LINK, "_self");
                                }} >
                                    <img src = {ok}/>
                                </div>
                            </div>
                    </div>
                    <div className = 'dowloadLinks content'>
                        <div className = "footerLinks" >
                            <h2> <Translate content = 'downloadHere' /> </h2>
                            <div className = 'paragraph2'>
                                <div className='footerLink' style={{ cursor: 'pointer' }} onClick={() => {
                                    window.open(GOOGLE_PLAY_LINK, "_self");
                                }} >
                                    <img src = {google}/>
                                </div>
                                <div className='footerLink' style={{ cursor: 'pointer' }} onClick={() => {
                                    window.open(APPLE_STORE_LINK, "_self");
                                }} >
                                    <img src = {appstore}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>
          )
      }
      showMore (status) {
        const target = document.getElementById('showMoreContent')
        if(status) {
            target.style.transform = 'scale(1,1)';
            target.style.display = 'block';
            document.getElementById('showLess').style.display = 'flex';
            document.getElementById('showMore').style.display = 'none';
        } else {
            target.style.transform = 'scale(1,0)';
            target.style.display = 'none';
            document.getElementById('showLess').style.display = 'none';
            document.getElementById('showMore').style.display = 'flex';
        }
    }
}

export default Footer;