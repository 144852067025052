export default {
    home:'Գլխավոր',
    news:'Նորություններ',
    contact:"Հետադարձ կապ",
    about:"Մեր մասին",
    MICRO_151:'ՄԻԿՐՈ 151',
    BIG_301:'ՄԵԾ 301',
    '2_PLAYER_GAME':'2 ՀՈԳԱՆՈՑ ԲԼՈՏ',
    '4_FRIENDS_GAME':'ԽԱՂ ԸՆԿԵՐՆԵՐԻ ՀԵՏ',
    'gameTypes':'ԽԱՂԻ ՏԻՊԵՐ',
    'aboutUs1' : 'Կարգին Բլոտը ստեղծվել է 2019թ-ի օգոստոսին և շատ կարճ ժամանակում հասցրել է դառնալ լավագույն բլոտ հավելվածը։Կարգին բլոտի նպատակն է ստեղծել օնլայն հայկական բազառ բլոտ խաղալու ամենակարգին հարթակը։',
    'aboutUs2' : 'Կագին Բլոտը միակ բլոտ հավելվածն է որն իր մեջ ներառում է բոլոր բլոտ տեսակները՝ բլոտ 301-ից, բլոտ 151-ից, բլոտ 4 ընկերներով, բլոտ ընկերոջ հետ (խաղա 4 հոգանոց բլոտ ընկերոջդ հետ), 2 հոգանոց բլոտ, "Ուսուցողական խաղ" սկսնակների համար․',
    'aboutUs3' : 'Kargin Blot-ն առաջարկում է յուրօրինակ խաղային փորձ իր հիանալի գրաֆիկակայով և հետաքրքիր հնարավորություններով, ինչպիսիք են՝',
    'blot4friends' : '- Բլոտ 4 ընկրների համար',
    'blot2friends' : '- Բլոտ ընկերոջ հետ',
    'blot151and301' : '- Բլոտ 151-ից և 301-ից',
    'blotfortwo' : '- Երկու հոգանոց բլոտ',
    'addFriend' : '- Ավելացնել ընկերներ',
    'powerHit' : '- Power Hit/ խաղաքարտ ճտցնել (սեղմած պահեք խաղաքարտը և բաց թողեք)',
    'themesFooter' : '- խաղի սեղանների տարբեր գույներ և խաղաքարտեր',
    'tariningFooter' : '- Անվճար ուսուցողական խաղ- հաղթի՛ր խաղը և ստացի՛ր 500 չիպ',
    'downloadFooter' : 'Ներբեռնի՛ր և խաղա՛ ավելի քան 100,000 իրական օգտատերերի և ընկերներիդ հետ:',
    'downloadFooter2' : 'Ստացեք բլոտի լավագույն խաղային փորձը Kargin Blot- ի հետ! 🥇',
    'playHere' : 'ԽԱՂԱՑԵՔ ԱՅՍՏԵՂ',
    'downloadHere' : 'ՆԵՐԲԵՌՆԵԼ ԱՅՍՏԵՂԻՑ',
    'lastNews' : 'ՎԵՐՋԻՆ ՆՈՐՈՒԹՅՈՒՆՆԵՐ',
    'suggestions' : 'Եթե ունեք հարցեր կամ առաջարկներ ապա գրեք մեզ',
    '301description' : 'Խաղա՛ բլոտ 301-ից (հասանելի է նաև "բլոտ 4 ընկերներով" խաղում):',
    '151description' : 'Խաղա՛ բլոտ 151-ից (հասանելի է նաև "բլոտ 4 ընկերներով" խաղում):',
    '2playerDesc' : 'Երկու հոգանոց բլոտ: \nԿլասիկ բլոտի սեղան, խաղա՛ քո ընկերոջ կամ այլ խաղացողների հետ:',
    '4playerDesc' : 'Չորս Ընկերներով Բլոտ Ստեղծի՛ր " 4 հոգանոց բլոտ" սեղան, կիսվի՛ր խաղի կոդերով կամ հղումով ընկերներիդ հետ և խաղացե՛ք միասին',
    'aboutUsHeader' : 'Բազառ բլոտի բոլոր տեսակները մեկ հավելվածում- Կարգին Բլոտ', 
    'features1' : 'Կարգին Բլոտը ստեղծվել է 2019թ-ի օգոստոսին և շատ կարճ ժամանակում հասցրել է դառնալ լավագույն բլոտ հավելվածը։ Կարգին Բլոտն առաջարկում է յուրահատուկ խաղային փորձ իր գեղեցիկ գրաֆիկ դիզայնով, հետաքրքիր ֆունկցիաներով, սեղանների տարբեր գույներով և շատ օրական բոնուսներով։ Կագին Բլոտը միակ բլոտ հավելվածն է որն իր մեջ ներառում է բոլոր բլոտ տեսակները՝ բլոտ 301-ից, բլոտ 151-ից, բլոտ 4 ընկերներով, բլոտ ընկերոջ հետ (խաղա 4 հոգանոց բլոտ ընկերոջդ հետ), 2 հոգանոց բլոտ- կլասիկ բլոտ,, "Ուսուցողական խաղ" սկսնակների համար․ Խաղա տարբեր բլոտ մրցաշարեր/ տուրնիրներ (Օրական, Շաբաթական, 2 հոգանոց) մյուս խաղացողների հետ և ստացի՛ր տարբեր մրցանակներ։',
    'aboutUsHeader2' : 'Խաղի ֆունկցիաներից են՝',
    'addFriendText' : '- Ավելացնել ընկերներ- ընկերներիդ կամ խաղի ընթացքում այլ խաղացողների ավելացրու որպես ընկեր և հրավիրի խաղ։',
    'colorsAndThemes' : '- խաղի սեղանների տարբեր գույներ և խաղաքարտեր',
    'features2' : ' Խաղը նաև ունի “Արգելափակման” ֆունկցիա, համակարգը չի հանդուրժում խաղը փչացնող օգտատերերին և արգելափակում է նրանց մյուս խաղացողների խաղային փորձը փչացնելու համար։  Խաղացողները նույնպես կարող են խաղերի ընթացքում արգելափակել այլ խաղացողներին և այլևս նրանց հետ չեն խաղա։ Մեր նպատակն է ստեղծել խաղ որտեղ բոլոր խաղացողները կստանան օնլայն բազար բլոտի արդար և հաճելի խաղային փորձ։',
    'features3' : 'Ամենը շնորհիվ մեր հրաշալի թիմի անդամների, ովքեր անըդնհատ կատարելագործում են խաղը և նոր ֆունկցիաներ են ավելացնում հետաքրքիր խաղեր ապահովելու համար։',
    'features4' : 'Հետևե՛ք մեզ սոցիալական ցանցերի մեր էջերում հավելյալ բոնուսների և մրցանակների համար։Կարգին բլոտի նպատակն է ստեղծել օնլայն հայկական բազառ բլոտ խաղալու ամենակարգին հարթակը։Ներբեռնի՛ր և խաղա՛ ավելի քան 100,000 իրական օգտատերերի և ընկերներիդ հետ Ստացեք բլոտի լավագույն խաղային փորձը Kargin Blot- ի հետ!',
    'ourTeam' : 'ՄԵՐ ԹԻՄԸ',
    'Varik' : 'Վարիկ',
    'Lilith' : 'Լիլիթ',
    'Ruben' : 'Ռուբեն',
    'Andrey' : 'Անդրեյ',
    
}