import React from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import karginLogo from '../assets/LOgo.png' 
import LocaleSwitcher from './LocaleSwitcher'
import { Link } from 'react-router-dom';
import '../page-styles/header.css'


counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
  } 
  else {
    counterpart.setLocale('am')
  }
  
class Header extends React.Component {
    constructor(props) {
        super(props)
    }

    render () {
        return (
            <div className = 'header'>
                <div className = 'barsCont' style = {{textAlign: 'initial'}} >
                <Link to = '/'  style = {{textAlign: 'initial',display: 'flex'}}><img src = {karginLogo} alt=""/></Link>
                    <a href="#" className="icon" onClick = {this.openSlideNav}>
                        <i className="fa fa-bars"></i>
                    </a>              
                </div>
                <div className = 'mainHeader' style = {{backgroundImage: `url(${this.props.backgroundImage})`}}>
                    <div className="nav" id="myTopnav">
                        <Link to = '/' ><Translate content = 'home'/></Link>
                        <Link to = '/news' ><Translate content = 'news'/></Link>
                        <Link to = '/contact' ><Translate content = 'contact'/></Link>
                        <Link to = '/about' ><Translate content = 'about'/></Link>
                        <a ><LocaleSwitcher color = {'white'} /></a>
                    </div>
                </div>
            </div>
        )
    }
    openSlideNav() {
        let direction = document.getElementById("mySidenav").style.width === "250px";
        document.getElementById("mySidenav").style.width = direction ? '0px' : '250px';
       }
     
}

export default Header;