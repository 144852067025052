export default {
    home:'Home',
    news:'News',
    contact:"Contact",
    about:"About",
    MICRO_151:'SMALL 151',
    BIG_301:'BIG 301',
    '2_PLAYER_GAME':'TWO PLAYER GAME',
    '4_FRIENDS_GAME':'GAME WITH FRIENDS',
    'gameTypes':'GAME TYPES',
    'aboutUs1': 'Kargin Blot was launched in August of 2019 with an aim of creating a platform where all the fans of Armenian bazar blot can come together and play their beloved game. Kargin Blot is a free blot app, which  offers a unique game experience with great graphic design, fun features and many daily bonuses',
    'aboutUs2': ' Kargin Blot is the only blot app which includes all the Blot types- blot 301 - blot151, blot with 4 friends - blot with 1 friend, blot for 2, and a "Training Game" for beginners to learn the game.',
    'aboutUs3' : 'UNIQUE FEATURES INCLUDE',
    'blot4friends' : '- Blot for 4 friends',
    'blot2friends' : '- Blot with a friend.',
    'blotForTwo' : '- Blot for 2',
    'blot151and301' : '- Blot 151 and 301',
    'addFriend' : '- Add friends',
    'powerHit' : '- Power Hit (hold down on a card and release)',
    'themesFooter' : '- Variety of themes for you to choose',
    'tariningFooter' : '- Free training game- win the game and get 500 chips!',
    'downloadFooter' : 'Download and play blot with more than 100.000 real users and with your friends! ',
    'downloadFooter2' : 'Get the best Blot game experience with Kargin Blot🥇',
    'playHere' : 'PLAY HERE',
    'lastNews' : 'LAST NEWS',
    'suggestions' : ' If you have suggestions or questions feel free to contact us!',
    '301description' : 'Play Blot 301 (Also available in the "Blot for 4 friends" game)',
    '151description' : 'Play Blot 151 (Also available in the "Blot for 4 friends" game)',
    '2playerDesc' : 'Blot For Two. Classic blot table- play with a friend or with other users',
    '4playerDesc' : 'Blot for four friends. Create a table "Blot with 4 friends", share the codes or the links with your friends and play together',
    'downloadHere' : 'DOWNLOAD HERE',
    'aboutUsHeader' : 'Every Blot type in just one app- Kargin Blot',
    'features1' : 'Kargin Blot was launched in August of 2019 with an aim of creating a platform where all the fans of Armenian bazar blot can come together and play their beloved game. Kargin Blot is a free online blot app, which offers a unique game experience with great graphic design, fun features and many daily bonuses. Kargin Blot is the only blot app which includes all the Blot types- blot 301 - blot 151, -blot with 4 friends, - blot with 1 friend, -blot for 2, and a -"Training Game" for beginners to learn the game.You can play various tournaments (Daily, Weekly and 2x2) and compete with other players for the best prizes!',
    'aboutUsHeader2' : 'UNIQUE FEATURES INCLUDE',
    'addFriendText' : '- Add friends - add your friends or any player you like during the games as a friend and invite them to play together as a team.',
    'colorsAndThemes' : '- Variety of themes and cards for you to choose ',
    'features2' : "The game also has a “Blocking players” feature, the system does not tolerate players who ruin others’ game experience by betting too high or another way and blocks such users. The players themselves can block other users and won't play with them again.",
    'features3' : 'Our aim is to create a platform where everyone can enjoy fair blot matches and get the best online blot experience! Everything is thanks to our incredible team which works hard and constantly improves the game quality and adds new features!',
    'features4' : 'Follow our social media pages for extra bonuses and prizes! Download and play blot with more than 100.000 real users and with your friends! Get the best Blot game experience with Kargin Blot🥇',
    'ourTeam' : 'OUR TEAM',
    'Varik' : 'Varik',
    'Lilith' : 'Lilith',
    'Ruben' : 'Ruben',
    'Andrey' : 'Andrey',
}