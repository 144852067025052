import React from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import karginLogo from '../assets/LOgo.png' 
import LocaleSwitcher from './LocaleSwitcher'
import { Link } from 'react-router-dom';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
  } 
  else {
    counterpart.setLocale('am')
  }
  

class Navbar extends React.Component {
    constructor(props) {
        super(props);

      }
    render() {
      return (
        <div className="topnav">
            <Link to = '/' className = 'active'><img src = {karginLogo} style = {{}}/></Link>
            <Link to = '/' ><Translate content = 'home'/></Link>
            <Link to = '/news' ><Translate content = 'news'/></Link>
            <Link to = '/contact' ><Translate content = 'contact'/></Link>
            <Link to = '/about' > <Translate content = 'about'/></Link>
            <a><LocaleSwitcher /></a>
            <a  className="icon" onClick = {this.props.openSlideNav}>
                <i className="fa fa-bars"></i>
            </a>
        </div>
      );
    }
}
export default Navbar;