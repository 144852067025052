import React from 'react';
import bg1 from  '../assets/151game.png';
import bg2 from '../assets/301game.png';
import bg3 from '../assets/2players.png';
import bg4 from '../assets/4players.png';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';

import karginLogo from '../assets/LOgo.png' 
import LocaleSwitcher from './LocaleSwitcher'
import counterpart from 'counterpart';

counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
  } 
  else {
    counterpart.setLocale('am')
  }
  
const TYPES = ['MICRO_151','BIG_301','2_PLAYER_GAME','4_FRIENDS_GAME'];
const DESCRIPTIONS = ['151description','301description','2playerDesc','4playerDesc'];
const bgs = [bg1,bg2,bg3,bg4]; 

class GameTypes extends React.Component {
    constructor(props){
        super(props)
    }
    render(){
        return (
            <div className = 'gameTypeCont'>
                <h2 className = 'gameTypeHeader'>
                    <Translate content = 'gameTypes' />
                </h2>
                <div className = 'typesConteiner'>
                    {TYPES.map((v,i) => {
                        return <Game key = {i} header = {v} description = {DESCRIPTIONS[i]} img = {bgs[i]} />
                    })}
                </div>
            </div>
        )
    }
}

class Game extends React.Component {
    constructor(props) {
        super(props)
    }
    render () {
        return (
            <div className = 'gameType'>
                <h2 className = 'gameTypeHeader'>  
                    <Translate content = {this.props.header} />
                </h2>
                <div className = 'content'>
                    <img src = {this.props.img} alt=""/>
                    <div className = 'description'>
                        <p>
                           <Translate content =  {this.props.description} />
                        </p>
                    </div>
                </div>
            </div>
        )
    }
} 

export default GameTypes;