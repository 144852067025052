import React from 'react';
import counterpart from 'counterpart';



class LocaleSwitcher extends React.Component {
  constructor(props){
    super(props)
  }
  handleChange(e) {
    counterpart.setLocale(e.target.value);
    localStorage.setItem('leng',e.target.value)
  }
   
    render() {
      return (
          <select defaultValue={counterpart.getLocale()} onChange={this.handleChange} style = {{color : this.props.color || 'black'}} >
            <option value = 'en'>English</option>
            <option value = 'ru'>Russian</option>
            <option value = 'am'>Armenian</option>
          </select>
      );
    }
  }

  export default LocaleSwitcher;