import React from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import en from '../leng/en';
import ru from '../leng/ru';
import am from '../leng/am';
import '../page-styles/news.css'
import { Link } from 'react-router-dom';
counterpart.registerTranslations('en', en);
counterpart.registerTranslations('ru', ru);
counterpart.registerTranslations('am', am);
if(localStorage.getItem('leng')){
    counterpart.setLocale(localStorage.getItem('leng'))
  } 
else {
    counterpart.setLocale('am')
}

class NewsElement extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        this.text = '';
        if(this.props.description.length > 150) {
            this.text = this.props.description.substring(0,180) + '...';           
        }
        return (
            <div className = 'news-element gameType ' style = {{height: 'auto' ,minWidth:'220px'}}>
                <div className = 'content' >
                    <Link to = {this.props.link}>
                    <img src = {this.props.photo} alt=""/>
                    <div className = 'description'>
                    <h2 className = 'newsHeader'>  
                        {this.props.header}
                    </h2>
                        <p>
                            {
                                this.text.length ? this.text : this.props.description
                            }
                        </p>
                    </div>
                    </Link>
                </div>   
            </div>
        )
    }
}
export default NewsElement;