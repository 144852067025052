export default {
    'home':'Главная',
    news:'Новости',
    contact:'Контакты',
    about:'О нас',
    MICRO_151:'МИКРО 151',
    BIG_301:'БОЛЬШАЯ 301',
    '2_PLAYER_GAME':'БЛОТ ДЛЯ 2Х ИГРОКОВ',
    '4_FRIENDS_GAME':'ИГРА С ДРУЗЬЯМИ',
    'gameTypes':'ТИПЫ ИГРЫ',
    'aboutUs1' : 'Каргин Блот был запущен в августе 2019 года с целью создания платформы, где все любители армянского базар блота могут собраться вместе и сыграть в свою любимую игру. Kargin Blot - бесплатное блот приложение, которое предлагает уникальный игровой опыт с великолепным графическим дизайном, забавными функциями и множеством ежедневных бонусов.',
    'aboutUs2' : ' Kargin Blot - единственное базар блот приложение, которое включает в себя все типы блота: блот 301 - блот 151, блот с 4 друзьями - блот с 1 другом, блот для 2 и «Обучающая игра» для начинающих.',
    'aboutUs3' : 'ОСОБЕННОСТИ ВКЛЮЧАЮТ',
    'blot4friends' : '- Блот для 4 друзей',
    'blot2friends' : '- Блот с другом',
    'blotfortwo' : '- Блот для двух 1x1',
    'blot151and301' : '- Блот 151 и 301',
    'addFriend' : '- Добавить друзей',
    'powerHit' : '- Power Hit (нажмите на карту и отпустите)',
    'themesFooter' : '- Разнообразие тем для вас на выбор',
    'tariningFooter' : '- Бесплатная обучающая игра - выиграй игру и получи 500 фишек!',
    'downloadFooter' : 'Скачайте и играйте с более чем 100 000 настоящих пользователей!',
    'downloadFooter2' : 'Получите лучший опыт игры блот с Kargin Blot 🥇',
    'playHere' : 'ИГРАЙ ЗДЕСЬ',
    'downloadHere' : 'СКАЧАТЬ ЗДЕСЬ',
    'lastNews' : 'ПОСЛЕДНИЕ НОВОСТИ',
    'suggestions' : 'Если у вас есть предложения или вопросы, свяжитесь с нами!',
    '301description' : 'Блот 301 (также доступно в игре "Блот с 4 друзьями")',
    '151description' : 'Блот 151 (также доступно в игре "Блот с 4 друзьями")',
    '2playerDesc' : 'Блот для двоих. Классический блот стол - играйте с другом или с другими игроками',
    '4playerDesc' : 'Блот с четырьмя друзьями. Создайте блот стол для 4-х игроков, делитесь кодами или ссылками стола/ игры с друзьями и играйте вместе.',
    'aboutUsHeader' : 'Все версии блота в одном приложении - Каргин Блот',
    'features1' : 'Каргин Блот был запущен в августе 2019 года с целью создания платформы, где все любители армянского базар блота могут собраться вместе и сыграть в свою любимую игру. Kargin Blot - бесплатное онлайн блот приложение, которое предлагает уникальный игровой опыт с великолепным графическим дизайном, забавными функциями и множеством ежедневных бонусов.Каргин Блот - единственное онлайн  базар блот приложение, которое включает в себя все типы блота: блот 301 - блот 151, -блот с 4 друзьями - блот с 1 другом, -блот для 2 и - «Обучающая игра» для начинающих. Вы можете играть в различных турнирах (Ежедневный, Еженедельный и 2x2) и соревноваться с другими игроками за лучшие призы!',
    'aboutUsHeader2' : 'ОСОБЕННОСТИ ВКЛЮЧАЮТ',
    'addFriendText' : '- Добавить друзей- добавьте своих друзей или любого игрока во время игр в друзья и пригласите их играть вместе в команде.',
    'colorsAndThemes' : '- Разнообразие тем и карт для вас на выбор',
    'features2' : 'В игре также есть функция «Блокировка игроков», система не допускает игроков, которые портят игровой опыт других, делая слишком высокие ставки или иным образом, и блокируют таких пользователей. Игроки также могут блокировать других игроков во время игр, больше с ними играть не будут․ Наша цель создать платформу, где каждый сможет насладиться честными блот матчами и получить лучший опыт онлайн-блота!',
    'features3' : 'Все благодаря нашей невероятной команде, которая много работает, постоянно улучшая качество игры и добавляет новые функции чтобы обеспечить интересные блот матчи для вас!',
    'features4' : 'Следите за нашими страницами в социальных сетях, для дополнительных бонусов и призов!',
    'ourTeam' : 'НАША КОМАНДА',
    'Varik' : 'Варик',
    'Lilith' : 'Лилит',
    'Ruben' : 'Рубен',
    'Andrey' : 'Андрей',
}